(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/request-racing-payment-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/request-racing-payment-error.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  ClientErrorMessages,
  ClientErrorCodes,
  ErrorFacillities
} = svs.components.payment.common.error.constants;
const {
  getErrorCodeValue
} = svs.components.payment.common.error.utils;
const {
  PaymentError
} = svs.components.payment.common.error;
const ErrorFacillityCodesRacing = Object.freeze({
  [ErrorFacillities.GATEWAY]: {
    112: ClientErrorCodes.PAYMENT_REQUEST_FAILURE_RACING
  },
  [ErrorFacillities.GAME]: {
    103: ClientErrorCodes.BAD_DRAW_RACING_CANCELLED_PARTICIPANT,
    105: ClientErrorCodes.BAD_DRAW_RACING_RACE_STARTED,
    107: ClientErrorCodes.BAD_DRAW_RACING_RACE_NOT_OPENED,
    124: ClientErrorCodes.BAD_DRAW_RACING_REG_BET_DISABLED
  }
});

class RequestRacingPaymentError extends PaymentError {
  constructor(_ref) {
    var _ErrorFacillityCodesR, _ClientErrorMessages$;
    let {
      clientCode = ClientErrorCodes.GENERIC_ERROR,
      code,
      extraData
    } = _ref;
    const [facillity, errorCode] = getErrorCodeValue(code);
    const racingError = ErrorFacillityCodesRacing === null || ErrorFacillityCodesRacing === void 0 || (_ErrorFacillityCodesR = ErrorFacillityCodesRacing[facillity]) === null || _ErrorFacillityCodesR === void 0 ? void 0 : _ErrorFacillityCodesR[errorCode];
    if (racingError) {
      clientCode = racingError;
    }
    const message = (_ClientErrorMessages$ = ClientErrorMessages[clientCode]) !== null && _ClientErrorMessages$ !== void 0 ? _ClientErrorMessages$ : "Generic error, errorCode: ".concat(code);
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'RequestRacingPaymentError';
  }
  get severity() {
    if (this.clientCode === ClientErrorCodes.BAD_BET) {
      return 'warn';
    }
    return super.severity;
  }
}
_defineProperty(RequestRacingPaymentError, "ClientErrorCodes", ClientErrorCodes);
setGlobal('svs.components.payment.common.error.RequestRacingPaymentError', RequestRacingPaymentError);

 })(window);