(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/wager-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/wager-error.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const ErrorCodes = {
  GENERIC: 0,
  INCOMPLETE_BET: 1000,
  UNSUPPORTED_VALUE_GENERIC: 1000,
  UNSUPPORTED_VALUE_PRODUCT_ID: 1001,
  UNSUPPORTED_VALUE_SYSTEM_TYPE: 1002,
  UNSUPPORTED_VALUE_REDUCTION: 1003,
  UNSUPPORTED_VALUE_BET_PROP: 1004,
  UNSUPPORTED_VALUE_BOARD_PROP: 1005,
  UNSUPPORTED_VALUE_ROW_PRICE: 1006,
  UNSUPPORTED_VALUE_SYSTEM_NUMBER: 1007,
  UNSUPPORTED_VALUE_DRAW_COUNT: 1008,
  EVENT_BOUNDS: 1009
};
const ClientErrorCodes = {};
var _code = new WeakMap();
class WagerError extends Error {
  constructor(_ref) {
    let {
      clientCode,
      message
    } = _ref;
    super({
      message
    });
    _classPrivateFieldInitSpec(this, _code, void 0);
    let codeToSet = ErrorCodes.GENERIC;
    if (Object.values(ErrorCodes).includes(clientCode)) {
      codeToSet = clientCode;
    }
    _classPrivateFieldSet(_code, this, codeToSet);
    this.name = 'WagerError';
  }
  get code() {
    return _classPrivateFieldGet(_code, this);
  }
  toJSON() {
    return {
      message: this.message
    };
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
}
_defineProperty(WagerError, "ClientErrorCodes", _objectSpread({}, ClientErrorCodes));
_defineProperty(WagerError, "ErrorCodes", _objectSpread({}, ErrorCodes));
setGlobal('svs.components.payment.common.error.WagerError', WagerError);

 })(window);