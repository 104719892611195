(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/system-type.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/system-type.js');
'use strict';

const SystemType = Object.freeze({
  MSYS: 'MSYS',
  SROW: 'SROW',
  RSYS: 'RSYS',
  USYS: 'USYS',
  UROW: 'UROW'
});
setGlobal('svs.components.payment.common.constants.SystemType', SystemType);

 })(window);