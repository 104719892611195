(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/bet-properties.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/bet-properties.js');
'use strict';

const BetProperties = Object.freeze({
  XPERTEN: 'XPERTEN',
  GROUP: 'GROUP',
  GROUP_B2C: 'GROUP_B2C',
  COMPETITION: 'COMPETITION',
  REDUCE_FREE: 'REDUCERA_FRITT',
  EXTERNAL_SYSTEMS: 'EGNARADER'
});
setGlobal('svs.components.payment.common.constants.BetProperties', BetProperties);

 })(window);