(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/constants.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/constants.js');
"use strict";


const ClientErrorCodes = Object.freeze({
  GENERIC: 0,
  NOT_ENOUGH_DATA: 1,
  NOT_WAGER: 2,
  NOT_FRACTION: 3,
  NOT_ENOUGH_MONEY: 1000,
  NOT_AUTHENTICATED: 2000,
  PAYMENT_REQUEST_FAILURE: 3000,
  PAYMENT_FETCH_FAILURE: 3001,
  PAYMENT_REQUEST_FAILURE_RACING: 3002,
  IMPOSSIBLE_ROWS: 3003,
  USER_HAS_EXCLUSION: 4000,
  PLAYER_PAUSED: 4001,
  VERTICAL_EXCLUSION: 4002,
  SUSPENSION: 4003,
  BAD_DRAW: 5000,
  BAD_DRAW_RACING_RACE_STARTED: 5001,
  BAD_DRAW_RACING_RACE_NOT_OPENED: 5002,
  BAD_DRAW_RACING_CANCELLED_PARTICIPANT: 5003,
  BAD_DRAW_RACING_REG_BET_DISABLED: 5004,
  BACKEND_ERROR: 6000,
  BAD_RESULT: 7000,
  BAD_BET: 8000,
  CUSTOMER_LIMIT: 9000,
  TIME_LIMIT: 9001,
  AMOUNT_LIMIT: 9002,
  DAILY_AMOUNT_LIMIT: 9003,
  ROW_LIMIT: 9004,
  COMPETITION_ERROR: 10000,
  INVALID_PARAM: 11002,
  MEMBER_NOT_FOUND: 11012,
  MEMBER_ACTIVE: 11019,
  GROUP_ACTIVE: 11020,
  ITS_LIMIT_EXCEEDED: 11022,
  NO_SHARES_AVAILABLE: 11023,
  OPS_INSUFFICIENT_FUNDS: 11024,
  OPS_ACCOUNT_NOT_REACHABLE: 11026,
  TECHNICAL_ERROR: 11027,
  CONTAINER_NOT_FOUND: 11036,
  ACTIVATED_DRAW_NOT_FOUND: 11037,
  NO_OPS_REPLY: 11043,
  NO_ITS_REPLY: 11044,
  MAX_SHARES_PER_MEMBER_REACHED: 11047,
  CANCEL_PAYMENT_FAILURE: 12000,
  CANCELATION_TIME_EXCEEDED: 12001,
  CANCELATION_STOPPED: 12002,
  CANCELATION_RETAILER_NOT_POSSIBLE: 12003
});
const MiddlewareErrorCodes = Object.freeze({
  GENERIC: 0,
  PAYMENT_ABORTED: 1,
  PAYMENT_ABORTED_MP_SHARES: 2,
  NOT_ENOUGH_MONEY: 3,
  NOT_AUTHENTICATED: 4,
  USER_HAS_EXCLUSION: 5,
  TIME_LIMIT: 6,
  GRASSROOT: 7,
  NOT_WAGER: 8,
  NOT_FRACTION: 9,
  GROUP_FETCH_ERROR: 10,
  GROUP_JOIN_ERROR: 11,
  CANCEL_ABORTED: 12
});
const MiddlewareErrorMessages = Object.freeze({
  [MiddlewareErrorCodes.GENERIC]: 'Unknown error',
  [MiddlewareErrorCodes.PAYMENT_ABORTED]: 'Customer aborted payment',
  [MiddlewareErrorCodes.PAYMENT_ABORTED_MP_SHARES]: 'Customer aborted payment, new shares bought for MP',
  [MiddlewareErrorCodes.NOT_ENOUGH_MONEY]: 'Not enough money',
  [MiddlewareErrorCodes.NOT_AUTHENTICATED]: 'Not authenticated',
  [MiddlewareErrorCodes.USER_HAS_EXCLUSION]: 'User paused or excluded',
  [MiddlewareErrorCodes.TIME_LIMIT]: 'Time limit exceeded',
  [MiddlewareErrorCodes.GRASSROOT]: 'Failed to fetch sponsorships',
  [MiddlewareErrorCodes.NOT_WAGER]: 'Instance is not wager',
  [MiddlewareErrorCodes.NOT_FRACTION]: 'Instance is not fraction',
  [MiddlewareErrorCodes.GROUP_FETCH_ERROR]: 'Failed to fetch group',
  [MiddlewareErrorCodes.GROUP_JOIN_ERROR]: 'Failed to join group',
  [MiddlewareErrorCodes.CANCEL_ABORTED]: 'Customer aborted cancelation'
});
const ClientErrorIcon = Object.freeze({
  [ClientErrorCodes.GENERIC]: 'exclamation-sign',
  [ClientErrorCodes.NOT_ENOUGH_DATA]: 'exclamation-sign',
  [ClientErrorCodes.NOT_WAGER]: 'exclamation-sign',
  [ClientErrorCodes.NOT_FRACTION]: 'exclamation-sign',
  [ClientErrorCodes.NOT_ENOUGH_MONEY]: 'money',
  [ClientErrorCodes.NOT_AUTHENTICATED]: 'exclamation-sign',
  [ClientErrorCodes.PAYMENT_REQUEST_FAILURE]: 'exclamation-sign',
  [ClientErrorCodes.PAYMENT_FETCH_FAILURE]: 'exclamation-sign',
  [ClientErrorCodes.IMPOSSIBLE_ROWS]: 'help-2',
  [ClientErrorCodes.CANCEL_PAYMENT_FAILURE]: 'help-2',
  [ClientErrorCodes.CANCELATION_TIME_EXCEEDED]: 'clock',
  [ClientErrorCodes.CANCELATION_STOPPED]: 'help-2',
  [ClientErrorCodes.CANCELATION_RETAILER_NOT_POSSIBLE]: 'help-2',
  [ClientErrorCodes.USER_HAS_EXCLUSION]: 'spelpaus',
  [ClientErrorCodes.BAD_DRAW]: 'exclamation-sign',
  [ClientErrorCodes.BACKEND_ERROR]: 'exclamation-sign',
  [ClientErrorCodes.BAD_RESULT]: 'exclamation-sign',
  [ClientErrorCodes.BAD_BET]: 'exclamation-sign',
  [ClientErrorCodes.CUSTOMER_LIMIT]: 'my-limits',
  [ClientErrorCodes.TIME_LIMIT]: 'time-limit',
  [ClientErrorCodes.PLAYER_PAUSED]: 'spelpaus',
  [ClientErrorCodes.AMOUNT_LIMIT]: 'my-limits',
  [ClientErrorCodes.DAILY_AMOUNT_LIMIT]: 'my-limits',
  [ClientErrorCodes.ROW_LIMIT]: 'my-limits',
  [ClientErrorCodes.SUSPENSION]: 'spelpaus',
  [ClientErrorCodes.VERTICAL_EXCLUSION]: 'help-2',
  [ClientErrorCodes.BAD_DRAW_RACING_REG_BET_DISABLED]: 'help-2'
});
const ClientErrorMessages = Object.freeze({
  [ClientErrorCodes.GENERIC]: 'Unknown error',
  [ClientErrorCodes.NOT_ENOUGH_DATA]: 'Not enough data to start payment process',
  [ClientErrorCodes.NOT_WAGER]: 'Instance is not of type Wager',
  [ClientErrorCodes.NOT_FRACTION]: 'Instance is not of type Fraction',
  [ClientErrorCodes.NOT_ENOUGH_MONEY]: 'Not enough money',
  [ClientErrorCodes.NOT_AUTHENTICATED]: 'Not authenticated',
  [ClientErrorCodes.PAYMENT_REQUEST_FAILURE]: 'Payment request failure',
  [ClientErrorCodes.PAYMENT_FETCH_FAILURE]: 'Payment fetch failure',
  [ClientErrorCodes.IMPOSSIBLE_ROWS]: 'Bet includes impossible rows',
  [ClientErrorCodes.CANCEL_PAYMENT_FAILURE]: 'Cancel wager failure',
  [ClientErrorCodes.CANCELATION_TIME_EXCEEDED]: 'Cancel wager time exceeded',
  [ClientErrorCodes.CANCELATION_STOPPED]: 'Cancel wager stopped on draw',
  [ClientErrorCodes.CANCELATION_RETAILER_NOT_POSSIBLE]: 'Cancel wager for retailer not possible online',
  [ClientErrorCodes.USER_HAS_EXCLUSION]: 'User paused or excluded',
  [ClientErrorCodes.BAD_DRAW]: 'Draw not playable',
  [ClientErrorCodes.BACKEND_ERROR]: 'Error in backend',
  [ClientErrorCodes.BAD_RESULT]: 'Error with result',
  [ClientErrorCodes.BAD_BET]: 'Bet is not playable',
  [ClientErrorCodes.CUSTOMER_LIMIT]: 'Customer exceeded limit',
  [ClientErrorCodes.TIME_LIMIT]: 'Customer exceeded limit',
  [ClientErrorCodes.PLAYER_PAUSED]: 'User paused',
  [ClientErrorCodes.AMOUNT_LIMIT]: 'Exceeded amount limit',
  [ClientErrorCodes.DAILY_AMOUNT_LIMIT]: 'Exceeded amount limit',
  [ClientErrorCodes.ROW_LIMIT]: 'Exceeded row limit',
  [ClientErrorCodes.SUSPENSION]: 'User suspension',
  [ClientErrorCodes.VERTICAL_EXCLUSION]: 'User NAR paused',
  [ClientErrorCodes.COMPETITION_ERROR]: 'Error with competition',
  [ClientErrorCodes.INVALID_PARAM]: 'Invalid parameter',
  [ClientErrorCodes.MEMBER_NOT_FOUND]: 'Member not found',
  [ClientErrorCodes.MEMBER_ACTIVE]: 'Member is active',
  [ClientErrorCodes.GROUP_ACTIVE]: 'Group is active',
  [ClientErrorCodes.ITS_LIMIT_EXCEEDED]: 'ITS limit exceeded',
  [ClientErrorCodes.NO_SHARES_AVAILABLE]: 'No shares available',
  [ClientErrorCodes.OPS_INSUFFICIENT_FUNDS]: 'OPS insufficient funds',
  [ClientErrorCodes.OPS_ACCOUNT_NOT_REACHABLE]: 'OPS account not reachable',
  [ClientErrorCodes.TECHNICAL_ERROR]: 'Technical error',
  [ClientErrorCodes.CONTAINER_NOT_FOUND]: 'Container not found',
  [ClientErrorCodes.ACTIVATED_DRAW_NOT_FOUND]: 'Activated draw not found',
  [ClientErrorCodes.NO_OPS_REPLY]: 'No OPS reply',
  [ClientErrorCodes.NO_ITS_REPLY]: 'No ITS reply',
  [ClientErrorCodes.MAX_SHARES_PER_MEMBER_REACHED]: 'Max shares per member reached'
});
const ErrorFacillities = Object.freeze({
  NOT_USED: 0,
  NOT_USED_2: 1,
  AGENT: 2,
  GATEWAY: 3,
  RNGW: 4,
  TM: 5,
  ASM: 6,
  RET: 7,
  GAME: 8,
  SCORE: 9,
  MCAST: 10,
  CAMP: 11,
  VAL: 12,
  LOTT: 13,
  SM: 14,
  LM: 15,
  TSM: 16,
  DCACHE: 17,
  WIN: 18,
  TSTORE: 19,
  MBVAL: 20,
  OMS: 21,
  SYN: 22,
  BRC: 23,
  SUB: 24,
  PAY: 25,
  VOM: 26,
  COMP: 27,
  PACC: 28,
  TACC: 29,
  RES: 30,
  INVALID_FACILLITY: 31
});
const ErrorFacillityCodes = Object.freeze({
  [ErrorFacillities.NOT_USED]: {},
  [ErrorFacillities.NOT_USED_2]: {
    GENERIC: {
      code: '019',
      clientCode: ClientErrorCodes.SUSPENSION
    }
  },
  [ErrorFacillities.AGENT]: {},
  [ErrorFacillities.GATEWAY]: {
    TAS_UNAVAILABLE: {
      code: '112',
      clientCode: ClientErrorCodes.PAYMENT_REQUEST_FAILURE
    }
  },
  [ErrorFacillities.RNGW]: {},
  [ErrorFacillities.TM]: {
    GENERIC: {
      code: '117',
      clientCode: ClientErrorCodes.GENERIC
    },
    GENERIC2: {
      code: '135',
      clientCode: ClientErrorCodes.SUSPENSION
    }
  },
  [ErrorFacillities.ASM]: {
    ASM_INVALID_HANDLE: {
      code: '101',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_ALIAS_LENGTH: {
      code: '102',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NUM_ERROR_BANKCARD: {
      code: '103',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_PASSWORD: {
      code: '104',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_ALREADY_EXISTS: {
      code: '105',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_EXPIRE_DATE_LENGTH: {
      code: '106',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CARD_NUMBER_LENGTH: {
      code: '107',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INSERT_ACCHIST_FAILED: {
      code: '108',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UPDATE_BALANCE_FAILED: {
      code: '109',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NOPRIV: {
      code: '110',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SERIAL_NUM: {
      code: '111',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_LOCK: {
      code: '112',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_PRIV: {
      code: '113',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CHANGE_PASSWORD_REQUIRED: {
      code: '114',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_CHECKSUM: {
      code: '115',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NOT_INTERNAL: {
      code: '116',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NOT_EXTERNAL: {
      code: '117',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OWNERSHIP_FAILURE: {
      code: '118',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OTHER_USER_ACCESSED: {
      code: '119',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_SESSION_NOT_ACTIVE: {
      code: '120',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NOT_ENOUGH_MONEY: {
      code: '121',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CUSTOMERNOTAUTHORIZED: {
      code: '122',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_USER_GROUP: {
      code: '123',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_NOT_CHANGED: {
      code: '124',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_LENGTH: {
      code: '125',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_NO_DIGIT: {
      code: '126',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SAME_DIGITS: {
      code: '127',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SEQUENCE: {
      code: '128',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_PERSONAL_ID: {
      code: '129',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_USERID: {
      code: '130',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_SUCH_PROPERTY: {
      code: '131',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PROPERTY_SET: {
      code: '132',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PROPERTY_CLEARED: {
      code: '133',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CLOSE_TOO_LATE: {
      code: '134',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OPEN_CLOSE_MISMATCH: {
      code: '135',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ADJUST_CUSTOMER_ACCOUNT: {
      code: '136',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_MONTRANS_FEE_NOT_ALLOWED: {
      code: '137',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_REF_NUM: {
      code: '138',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_REASON: {
      code: '139',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_TYPE: {
      code: '140',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_REQ_TYPE: {
      code: '141',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_CUSTOMER: {
      code: '142',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_SEQ_NUM: {
      code: '143',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_AMOUNT: {
      code: '144',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_MONTRANS_FEE: {
      code: '145',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BELOW_LIMIT: {
      code: '146',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ABOVE_LIMIT: {
      code: '147',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_FUNCTION_CLOSED: {
      code: '148',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_ACCOUNT_USAGE: {
      code: '149',
      clientCode: ClientErrorCodes.PLAYER_PAUSED
    },
    ASM_ABORTED_SEQ_NUM: {
      code: '150',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_FREE_IBANKIDX: {
      code: '151',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_REF_NUM_NOT_ALLOWED: {
      code: '152',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_REF_NUM_MISSING: {
      code: '153',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DRAW_LIMIT_REACHED: {
      code: '154',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INCORRECT_WAGER_BUDGET: {
      code: '155',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PROPERTY_CHANGE: {
      code: '156',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_GROUP_NOT_CHANGED: {
      code: '157',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TIMEOUT: {
      code: '158',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_FUNCTION_CLOSED_DAYTIME: {
      code: '159',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BANKCARD_NOT_ALLOWED: {
      code: '160',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PENDING_LIMIT_REACHED: {
      code: '161',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UNABLE_TO_PROCESS: {
      code: '162',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_MERCHANT_NOT_ON_FILE: {
      code: '163',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_TRANSACTION: {
      code: '164',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_CARD_NO: {
      code: '165',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_EXPIRED_CARD: {
      code: '166',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_RESTRICTED_CARD_PICK_UP: {
      code: '167',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_STOLEN_CARD_PICK_UP: {
      code: '168',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NOT_SUFFICIENT_FUNDS: {
      code: '169',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_EXCEEDS_AMOUNT_LIMIT: {
      code: '170',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DUPLICATE_TRANSACTION: {
      code: '171',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UNABLE_TO_AUTHORIZE: {
      code: '172',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ABOVE_DAILY_LIMIT: {
      code: '173',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CARD_NOT_SUPPORTED: {
      code: '174',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BANKACCOUNT_NOT_ALLOWED: {
      code: '175',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PENDING_BANKCARD: {
      code: '176',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_ACTIVE_ACCESS_PASSWORD: {
      code: '177',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DENIALED_ACCESS_PASSWORD: {
      code: '178',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_ACCESS_TYPE: {
      code: '179',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_EXPIRED_ACCESS_PASSWORD: {
      code: '180',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_ACCESS_PASSWORD: {
      code: '181',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_ALREADY_LOCKED: {
      code: '182',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_PERMANENTLY_LOCKED: {
      code: '183',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_ALREADY_UNLOCKED: {
      code: '184',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACTIVE_ACCESS_PASSWORD: {
      code: '185',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_HANDLE: {
      code: '186',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UPD_IN_PROGRESS: {
      code: '187',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_GLOBAL_UPD_IN_PROGRESS: {
      code: '188',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_PRODUCT_ID: {
      code: '189',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NOT_ACTIVE: {
      code: '190',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_DEACTIVATED: {
      code: '191',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_NOT_SET: {
      code: '192',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_PERSONAL_ID: {
      code: '193',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UNDER_AGE_PLAYER: {
      code: '194',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TOO_YOUNG: {
      code: '195',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PERSONAL_ID_MISSING: {
      code: '196',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NEGATIVE_WAGER_AMOUNT: {
      code: '197',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WAGER_BUDGET_EXCEEDED: {
      code: '198',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OVER_WAGER_LIMIT: {
      code: '199',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OVER_LANGEN_LIMIT: {
      code: '200',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OVER_SINGLE_LIMIT: {
      code: '201',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OVER_DOUBLE_LIMIT: {
      code: '202',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_OVER_TRIPLE_LIMIT: {
      code: '203',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INSERT_WINGEN_FAILED: {
      code: '204',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_HIGHWIN_NOT_FOUND: {
      code: '205',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_MAX_READ_REQ_EXCEEDED: {
      code: '206',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_SUCH_WAGER: {
      code: '207',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_WINSTATE: {
      code: '208',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UNABLE_TO_MAP_WINFILE: {
      code: '209',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_WINFILE_HEADER: {
      code: '210',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_WINFILE: {
      code: '211',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINSUM_DISCREPANCY: {
      code: '212',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINMGR_SUBMIT: {
      code: '213',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINPAYOUT_IN_PROGRESS: {
      code: '214',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_CHKPOINT_IN_PROGRESS: {
      code: '215',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINSTATE_NOT_FOUND: {
      code: '216',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINPAYOUT_REQUESTED: {
      code: '217',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACCUPD_REQUESTED: {
      code: '218',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINPAYOUT_STARTED: {
      code: '219',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACCUPD_STARTED: {
      code: '220',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WINPAYOUT_DONE: {
      code: '221',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACCUPD_DONE: {
      code: '222',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_SUCH_WIN: {
      code: '223',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SELECT_WINGEN_FAILED: {
      code: '224',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UPDATE_WINGEN_FAILED: {
      code: '225',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBMIT_FAILED: {
      code: '226',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_PRIV_LEVEL: {
      code: '227',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_USER_TYPE: {
      code: '228',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_MESSAGE_NOT_SUPPORTED: {
      code: '229',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_USER_ID: {
      code: '230',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SESSION_NOT_ACTIVE: {
      code: '231',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INPUT_STRING_TOO_LONG: {
      code: '232',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NOT_FOUND: {
      code: '233',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_FREE_USER_IN_LIST: {
      code: '234',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DIFFERENT_USERS: {
      code: '235',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_PRIVILEGE: {
      code: '236',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_REQUEST_IN_PROGRESS: {
      code: '237',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_USER_CHECKSUM: {
      code: '238',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_TARGET_USER_CHECKSUM: {
      code: '239',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_TARGET_USER_TYPE: {
      code: '240',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_IMPERSONATED_USER: {
      code: '241',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SOURCE: {
      code: '242',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_OLD_PASSWORD: {
      code: '243',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_NOT_FOUND: {
      code: '244',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_ALREADY_EXISTS: {
      code: '245',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ALIAS_ALREADY_EXISTS: {
      code: '246',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PENDING_BANKCARD_EXISTS: {
      code: '247',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INPUT_STRING_TOO_SHORT: {
      code: '248',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INVALID_STATUS_CHANGE: {
      code: '249',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PRIV_LEVEL_NOT_CHANGED: {
      code: '250',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_OPENHOURS_DATE: {
      code: '251',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_OPENHOURS_MEDIA: {
      code: '252',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_OPENHOURS_NUM_ITEMS: {
      code: '253',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_OPENHOURS_TIME: {
      code: '254',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INSERT_ACCUPD_FAILED: {
      code: '255',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UPDATE_ACCUPD_FAILED: {
      code: '256',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SELECT_ACCUPD_FAILED: {
      code: '257',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACCUPDSTATE_NOT_FOUND: {
      code: '258',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_ACCUPDSTATE: {
      code: '259',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ACCUPD_IN_PROGRESS: {
      code: '260',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_LOGGED_ON: {
      code: '261',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SAME_USER: {
      code: '262',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_NOT_VERIFIED: {
      code: '263',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_UNDER_AGE_CASINO: {
      code: '264',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PERSONAL_ID_ALREADY_EXISTS: {
      code: '265',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ALIAS_NOT_FOUND: {
      code: '266',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SOURCE_NOT_ACTIVE: {
      code: '267',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BANK_MANAGER_NOT_ACTIVE: {
      code: '268',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_MONEVENT_NOT_FOUND: {
      code: '269',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SAME_MONSTATE: {
      code: '270',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INCORRECT_MONSTATE_CHANGE: {
      code: '271',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_TERMINATED: {
      code: '272',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_NOT_ACTIVE: {
      code: '273',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_DEACTIVATED: {
      code: '274',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_TARGET_USER_TERMINATED: {
      code: '275',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ABOVE_WEEKLY_LIMIT: {
      code: '276',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_ABOVE_MONTHLY_LIMIT: {
      code: '277',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BALANCE_ABOVE_DEPOSIT_LIMIT: {
      code: '278',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_PARAMETER_VALUE: {
      code: '279',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_BLOCKED: {
      code: '280',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SURPLUS_TYPE: {
      code: '281',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SYNDICATE_CHECKSUM: {
      code: '282',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SYNDICATE_SEQ_NUM: {
      code: '283',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_RETRY_INFORMATION: {
      code: '284',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SYNDICATE_ID: {
      code: '285',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_TRANS_TYPE: {
      code: '286',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INCORRECT_DEPOSIT_BUDGET: {
      code: '287',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DEPOSIT_BUDGET_EXCEEDED: {
      code: '288',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DEPOSIT_BUDGET_NOT_SET: {
      code: '289',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_BANK_TYPE: {
      code: '290',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_NO_SLOTS_AVAILABLE: {
      code: '291',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_INCORRECT_XFER_DATA: {
      code: '292',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SLOT_STATUS: {
      code: '293',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_EXPIRE_DATE: {
      code: '294',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_CARD_DIGITS: {
      code: '295',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BANKCARD_NOT_FOUND: {
      code: '296',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PENDING_TRANSACTION: {
      code: '297',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NAME_NOT_FOUND: {
      code: '298',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NAME_ALREADY_EXISTS: {
      code: '299',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NAME_INVALID: {
      code: '300',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_NO_LETTER: {
      code: '301',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_BIRTHDATE: {
      code: '302',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SSN_LAST4: {
      code: '303',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SEQ_ALPHA: {
      code: '304',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SEQ_DIGIT: {
      code: '305',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_SAME_CHARS: {
      code: '306',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_USERNAME: {
      code: '307',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_FIRST_NAME: {
      code: '308',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PASSWORD_LAST_NAME: {
      code: '309',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_USER_NAME_NO_LETTER: {
      code: '310',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BACKUP_IN_PROGRESS: {
      code: '311',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PURGE_IN_PROGRESS: {
      code: '312',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PURGE_NOT_IN_PROGRESS: {
      code: '313',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_RETRANSMIT_DATA: {
      code: '314',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_SEQUENCE_NUM: {
      code: '315',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_NUM_RECORDS: {
      code: '316',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_TOTAL_AMOUNT: {
      code: '317',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_PURGETYPE: {
      code: '318',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_ITSMODULE: {
      code: '319',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBSCRIPTION_ALR_EXISTS: {
      code: '320',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBSCRIPTION_NOT_FOUND: {
      code: '321',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBSCRIPTION_USER_MISMATCH: {
      code: '322',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBSCRIPTION_NOT_WINNER: {
      code: '323',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_REVIEW_AMOUNT_EXCEEDED: {
      code: '324',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_POKER_SUSPENDED: {
      code: '325',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SYNDICATES_SUSPENDED: {
      code: '326',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_SUBSCRIPTIONS_SUSPENDED: {
      code: '327',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_BANK_XFERS_SUSPENDED: {
      code: '328',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_WRONG_PINCODE: {
      code: '329',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PINCODE_NOT_SET: {
      code: '330',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_PINCODE_ERROR_EXCEEDED: {
      code: '331',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_KYC_SELL_DISABLED: {
      code: '332',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DEPOSIT_DAY_LIMIT_EXCEEDED: {
      code: '333',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DEPOSIT_WEEK_LIMIT_EXCEEDED: {
      code: '334',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DEPOSIT_MONTH_LIMIT_EXCEEDED: {
      code: '335',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_DAY_TIME_DISABLED: {
      code: '336',
      clientCode: ClientErrorCodes.GENERIC
    },
    ASM_LIMITS_LOCKED: {
      code: '337',
      clientCode: ClientErrorCodes.GENERIC
    } 
  },
  [ErrorFacillities.RET]: {},
  [ErrorFacillities.GAME]: {
    GAME_IMPOSSIBLE_ROWS: {
      code: '3',
      clientCode: ClientErrorCodes.IMPOSSIBLE_ROWS
    },
    GAME_LAST_MINUTE_CHANGE: {
      code: '103',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_DRAWNUMBER: {
      code: '104',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_DRAWSTATE: {
      code: '105',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_DRAW_NOT_OPENED: {
      code: '107',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_CANCELATION_STOPPED: {
      code: '122',
      clientCode: ClientErrorCodes.CANCELATION_STOPPED
    },
    GAME_REGBET_DISABLED: {
      code: '124',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_CANCEL_TIME_EXCEEDED: {
      code: '171',
      clientCode: ClientErrorCodes.CANCELATION_TIME_EXCEEDED
    },
    GAME_CANCELATION_RETAILER_NOT_POSSIBLE: {
      code: '199',
      clientCode: ClientErrorCodes.CANCELATION_RETAILER_NOT_POSSIBLE
    },
    GAME_CANCEL_LIVESPEL: {
      code: '200',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_UNABLE_TO_START_WINPAYOUT: {
      code: '201',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_MAX_ROW_WINAMOUNT_EXCEEDED: {
      code: '202',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_FUND_IS_BUSY: {
      code: '203',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_BAD_RESULT_COMBINATION: {
      code: '204',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_CANCEL_SUBSCRIPTION_BET: {
      code: '205',
      clientCode: ClientErrorCodes.GENERIC
    },
    GAME_WINGEN_DISCREPANCY: {
      code: '206',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_PRODUCT_CLOSED: {
      code: '207',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_EVENT_REPLACED: {
      code: '208',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_LIMIT_ALREADY_DEFINED: {
      code: '209',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_BAD_NUMEVENTS_IN_BET: {
      code: '210',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_REGBET_IN_PROGRESS: {
      code: '211',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_UNBETTABLE_ODDS: {
      code: '212',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_UNBETTABLE_SCORECAST_COMB: {
      code: '213',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_OUTCOME_CANCELLED: {
      code: '214',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_LIVEBET_COMB: {
      code: '215',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_LIVEBET_TOO_EARLY: {
      code: '216',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BAD_LIVEBET_CONTEXT: {
      code: '217',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_LIVEBET_TIMEOUT: {
      code: '218',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_CANCEL_NOT_ALLOWED: {
      code: '219',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_FRAC_DISCREPANCY: {
      code: '220',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_REQUEST_TOO_EARLY: {
      code: '221',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_CUST_MAX_DRAW_REGAMT: {
      code: '222',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_CUST_MAX_DRAW_EGNARADER_REGAMT: {
      code: '223',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_CUST_MAX_DAILY_PRODUCT_REGAMT: {
      code: '224',
      clientCode: ClientErrorCodes.DAILY_AMOUNT_LIMIT
    },
    GAME_SYNDICATE_MAX_DRAW_REGAMT: {
      code: '225',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_SYNDICATE_MAX_DRAW_EGNARADER_REGAMT: {
      code: '226',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_SYNDICATE_MAX_DAILY_PRODUCT_REGAMT: {
      code: '227',
      clientCode: ClientErrorCodes.DAILY_AMOUNT_LIMIT
    },
    GAME_RETAILER_MAX_DRAW_REGAMT: {
      code: '228',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_RETAILER_MAX_DRAW_ANDELSSPEL_REGAMT: {
      code: '229',
      clientCode: ClientErrorCodes.AMOUNT_LIMIT
    },
    GAME_RETAILER_MAX_DAILY_PRODUCT_REGAMT: {
      code: '230',
      clientCode: ClientErrorCodes.DAILY_AMOUNT_LIMIT
    },
    GAME_ODDS_TOO_LOW: {
      code: '231',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_ODDS_TOO_HIGH: {
      code: '232',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BET_AMOUNT_TOO_LOW: {
      code: '233',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BET_AMOUNT_TOO_HIGH: {
      code: '234',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_CUST_MAX_DAILY_LIVE_REGAMT: {
      code: '235',
      clientCode: ClientErrorCodes.CUSTOMER_LIMIT
    },
    GAME_BAD_DEFSRC: {
      code: '236',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_UNSUPPORTED_EVENTDEF: {
      code: '237',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_LIVE_DRAW_CONFLICT: {
      code: '238',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_CUST_MAX_BOARDS_IN_DRAW: {
      code: '239',
      clientCode: ClientErrorCodes.ROW_LIMIT
    },
    GAME_SYNDICATE_MAX_BOARDS_IN_DRAW: {
      code: '240',
      clientCode: ClientErrorCodes.ROW_LIMIT
    },
    GAME_TOO_MANY_FAILED_EVENTS: {
      code: '241',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_TOO_MANY_EVENTS_IN_DRAW: {
      code: '242',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_DRAW_CLOSED_BY_OWN: {
      code: '243',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_DRAW_CLOSED_BY_ODDSIMP: {
      code: '244',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_UNABLE_TO_ARCHIVE: {
      code: '245',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_BETAMT_INCLUDES_IMPROWS: {
      code: '246',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BET_CONTAINS_ONLY_IMPROWS: {
      code: '247',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BET_AMOUNT_TOO_LOW_AFTER_IMPROW_RED: {
      code: '248',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BAD_AUDITSTATE: {
      code: '249',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_BAD_AUDITSERIAL: {
      code: '250',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_CUSTSUP_MAX_SINGLE_BETS_EXCEEDED: {
      code: '251',
      clientCode: ClientErrorCodes.CUSTOMER_LIMIT
    },
    GAME_BAD_RANKING_LIST: {
      code: '252',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_FAILED_EXT_REGBET_REQUEST: {
      code: '253',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_FAILED_EXT_CANCELBET_REQUEST: {
      code: '254',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_TPPROXY_TIMEOUT: {
      code: '255',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_BAD_RACE_STATE: {
      code: '256',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_BET_STATE: {
      code: '257',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BET_ALREADY_COMPLETE: {
      code: '258',
      clientCode: ClientErrorCodes.BAD_BET
    },
    GAME_BAD_REFUND_AMOUNT: {
      code: '259',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    GAME_OPENHOURS_CONFLICT_WITH_NEXT_DRAW: {
      code: '260',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_OPENHOURS_CONFLICT_WITH_PREV_DRAW: {
      code: '261',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_WINCOUNT_TIME: {
      code: '262',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_START_DATE: {
      code: '263',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_STOP_DATE: {
      code: '264',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_DATE_CONFLICT_WITH_OTHER_DRAW: {
      code: '265',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_INCORRECT_NUM_PART_DRAWS: {
      code: '266',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_DRAWTYPE: {
      code: '267',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_PARTICIPATION_TERMS: {
      code: '268',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_BAD_WINTYPE: {
      code: '269',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_INCORRECT_DIV_WINNERS: {
      code: '270',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_INCORRECT_TOT_WINNERS: {
      code: '271',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_DATE_CONFLICT_BETWEEN_RCV_INTERVALS: {
      code: '272',
      clientCode: ClientErrorCodes.BAD_DRAW
    },
    GAME_INCORRECT_TOT_WINAMOUNT: {
      code: '273',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_WINCOUNT_TOO_EARLY: {
      code: '274',
      clientCode: ClientErrorCodes.BAD_RESULT
    },
    GAME_SPELADMIN_REQUEST_MAXTIME: {
      code: '275',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    }
  },
  [ErrorFacillities.SCORE]: {},
  [ErrorFacillities.MCAST]: {},
  [ErrorFacillities.CAMP]: {},
  [ErrorFacillities.VAL]: {},
  [ErrorFacillities.LOTT]: {},
  [ErrorFacillities.SM]: {},
  [ErrorFacillities.TSM]: {},
  [ErrorFacillities.DCACHE]: {},
  [ErrorFacillities.WIN]: {},
  [ErrorFacillities.TSTORE]: {},
  [ErrorFacillities.MBVAL]: {},
  [ErrorFacillities.OMS]: {},
  [ErrorFacillities.SYN]: {},
  [ErrorFacillities.BRC]: {},
  [ErrorFacillities.SUB]: {},
  [ErrorFacillities.PAY]: {},
  [ErrorFacillities.VOM]: {},
  [ErrorFacillities.COMP]: {
    COMP_INCORRECT_PARAMETER: {
      code: '3',
      clientCode: ClientErrorCodes.COMPETITION_ERROR
    },
    COMP_COMPETITION_NOT_FOUND: {
      code: '101',
      clientCode: ClientErrorCodes.COMPETITION_ERROR
    },
    COMP_ACTIVE_COMPETITION_NOT_OK: {
      code: '102',
      clientCode: ClientErrorCodes.COMPETITION_ERROR
    },
    COMP_SIGNUP_TO_EARLY: {
      code: '106',
      clientCode: ClientErrorCodes.COMPETITION_ERROR
    },
    COMP_ACTIVE_BET_NOT_OK: {
      code: '103',
      clientCode: ClientErrorCodes.COMPETITION_ERROR
    } 
  },
  [ErrorFacillities.PACC]: {
    PACC_NOT_USED: {
      code: '0',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_ERROR: {
      code: '1',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_INVALID_MSG_ID: {
      code: '2',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_INCORRECT_PARAMETER: {
      code: '3',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_TIMEOUT: {
      code: '4',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_MODULE_NOT_AVAILABLE: {
      code: '5',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_FILE_ERROR: {
      code: '6',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_NO_DATA_FOUND: {
      code: '7',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_INVALID_MSG_VERSION: {
      code: '8',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_SIZE_ERROR: {
      code: '9',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_NO_IPC_BUFFERS: {
      code: '10',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_NO_PRIVILEGE: {
      code: '11',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_PROCESS_IS_BUSY: {
      code: '12',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_INVALID_PROTOCOL_VERSION: {
      code: '13',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_NATIONAL_BLOCKED: {
      code: '14',
      clientCode: ClientErrorCodes.USER_HAS_EXCLUSION
    },
    PACC_CREATE_RESERVE_ID: {
      code: '101',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_UPDATE_RESERVE_ID: {
      code: '102',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_HTTP_ERROR: {
      code: '103',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_PARSE_RESPONSE: {
      code: '104',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_TECHNICAL_ERROR_SPELPAUS: {
      code: '105',
      clientCode: ClientErrorCodes.USER_HAS_EXCLUSION
    },
    PACC_NOT_ENOUGH_MONEY: {
      code: '106',
      clientCode: ClientErrorCodes.NOT_ENOUGH_MONEY
    },
    PACC_USER_NOT_FOUND: {
      code: '107',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_USER_BLOCKED: {
      code: '108',
      clientCode: ClientErrorCodes.GENERIC
    },
    PACC_LIMIT_REACHED: {
      code: '109',
      clientCode: ClientErrorCodes.TIME_LIMIT
    },
    PACC_WRONG_TARGET_USER: {
      code: '110',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_WRONG_RESERVE_ID: {
      code: '111',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    },
    PACC_WRONG_PAM_AMOUNT: {
      code: '112',
      clientCode: ClientErrorCodes.BACKEND_ERROR
    } 
  },
  [ErrorFacillities.TACC]: {},
  [ErrorFacillities.RES]: {}
});
setGlobal('svs.components.payment.common.error.constants', {
  ErrorFacillities,
  ErrorFacillityCodes,
  ClientErrorMessages,
  ClientErrorCodes,
  ClientErrorIcon,
  MiddlewareErrorCodes,
  MiddlewareErrorMessages
});

 })(window);