(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/services/fetch-sponsorships.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/services/fetch-sponsorships.js');

'use strict';

const fetchSponsorships = async () => {
  const {
    response
  } = await svs.core.jupiter.callAsync({
    method: 'GET',
    path: '/player/1/sponsorship/receiverselection?active=true'
  });
  if (response.error) {
    var _response$error;
    throw new Error((_response$error = response.error) === null || _response$error === void 0 ? void 0 : _response$error.message);
  }
  return response.receivers;
};
setGlobal('svs.components.payment.common.services.fetchSponsorships', fetchSponsorships);

 })(window);