(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/utils.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/utils.js');
"use strict";


const {
  logger
} = svs.components.payment.common;
const {
  ClientErrorCodes,
  ErrorFacillityCodes
} = svs.components.payment.common.error.constants;
const getTitleAndErrorMessageFromClientError = clientCode => {
  switch (clientCode) {
    case ClientErrorCodes.GENERIC:
      return {
        title: 'Tekniskt fel',
        message: 'Något gick fel vid betalningen, försök igen om en liten stund.'
      };
    case ClientErrorCodes.NOT_PAYABLE:
    case ClientErrorCodes.NOT_WAGER:
      return {
        title: 'Tekniskt fel',
        message: 'Något gick fel vid betalningen.'
      };
    case ClientErrorCodes.NOT_ENOUGH_MONEY:
      return {
        title: 'För lite pengar',
        message: 'Ditt spelsaldo har inte tillräckligt med pengar.'
      };
    case ClientErrorCodes.NOT_ENOUGH_DATA:
    case ClientErrorCodes.NOT_AUTHENTICATED:
    case ClientErrorCodes.PAYMENT_REQUEST_FAILURE:
    case ClientErrorCodes.PAYMENT_FETCH_FAILURE:
    case ClientErrorCodes.BACKEND_ERROR:
    case ClientErrorCodes.BAD_RESULT:
    case ClientErrorCodes.BAD_BET:
      return {
        title: 'Något gick fel',
        message: 'Något gick fel vid betalningen, vänligen försök igen senare.'
      };
    case ClientErrorCodes.CANCEL_PAYMENT_FAILURE:
      return {
        title: 'Spelet kunde inte annulleras',
        message: 'Något gick fel vid annullering, vänligen förösök igen senare.'
      };
    case ClientErrorCodes.CANCELATION_TIME_EXCEEDED:
      return {
        title: 'Spelet kunde inte annulleras',
        message: 'Det är längre än 30 min sen du lade detta spel.'
      };
    case ClientErrorCodes.CANCELATION_STOPPED:
      return {
        title: 'Annulleringar är stoppade på denna omgång.',
        message: 'Spelet kunde inte annulleras'
      };
    case ClientErrorCodes.CANCELATION_RETAILER_NOT_POSSIBLE:
      return {
        title: 'Ombudsspel kan inte annulleras på nätet',
        message: 'Du behöver gå till ombudet där spelet lades och annullera det under samma dag. Ta med ditt kvitto.'
      };
    case ClientErrorCodes.IMPOSSIBLE_ROWS:
      return {
        title: 'Omöjlig spelkombination',
        message: 'Spelet gick inte att betala på grund av att spelkombinationen inte är möjlig att vinna på.<br />'
      };
    case ClientErrorCodes.USER_HAS_EXCLUSION:
      return {
        title: 'Något gick fel',
        message: 'Något gick fel vid betalningen.'
      };
    case ClientErrorCodes.BAD_DRAW:
      return {
        title: 'Stängt för spel',
        message: 'Spelomgången går inte att spela på just nu.'
      };
    case ClientErrorCodes.CUSTOMER_LIMIT:
      return {
        title: 'Du har överskridit en gräns',
        message: 'Något gick fel vid betalningen, du har överskridit en gräns.'
      };
    case ClientErrorCodes.TIME_LIMIT:
      return {
        title: 'Du har nått din tidsgräns',
        message: 'När du nått din tidsgräns kan du inte betala för spel på Sport & Casino.'
      };
    case ClientErrorCodes.PLAYER_PAUSED:
      return {
        title: 'Ditt konto är spärrat',
        message: 'Kontakta kundservice för mer information.'
      };
    case ClientErrorCodes.AMOUNT_LIMIT:
      return {
        title: 'Du har överskridit en gräns',
        message: 'Högsta totala insats på omgången har överskridits.'
      };
    case ClientErrorCodes.DAILY_AMOUNT_LIMIT:
      return {
        title: 'Du har överskridit en gräns',
        message: 'Högsta totala dagliga insats på denna produkt har överskridits.'
      };
    case ClientErrorCodes.ROW_LIMIT:
      return {
        title: 'Du har överskridit en gräns',
        message: 'Högsta antalet rader på denna omgång har överskridits.'
      };
    case ClientErrorCodes.COMPETITION_ERROR:
      return {
        title: 'Något gick fel',
        message: 'Det går tyvärr inte att spela på tävlingen just nu.'
      };
    case ClientErrorCodes.PAYMENT_REQUEST_FAILURE_RACING:
      return {
        title: 'Betalningen misslyckades',
        message: 'Just nu har vi problem med att ansluta till vår samarbetspartner, vänligen försök igen senare.'
      };
    case ClientErrorCodes.BAD_DRAW_RACING_CANCELLED_PARTICIPANT:
      return {
        title: 'Struken häst',
        message: 'Spelet går inte att lägga eftersom en eller flera av dina valda hästar har strukits.'
      };
    case ClientErrorCodes.BAD_DRAW_RACING_RACE_STARTED:
      return {
        title: 'Stängt för spel',
        message: 'Loppet har startat och vi tar inte emot fler spel.'
      };
    case ClientErrorCodes.BAD_DRAW_RACING_RACE_NOT_OPENED:
      return {
        title: 'Stängt för spel',
        message: 'Loppet har inte öppnat för spel, vänligen försök senare.'
      };
    case ClientErrorCodes.BAD_DRAW_RACING_REG_BET_DISABLED:
      return {
        title: 'Stängt för spel',
        message: 'Just nu går det inte att lägga detta spel, vänligen försök senare.'
      };
    case ClientErrorCodes.INVALID_PARAM:
    case ClientErrorCodes.OPS_ACCOUNT_NOT_REACHABLE:
    case ClientErrorCodes.TECHNICAL_ERROR:
      return {
        title: 'Något gick fel vid betalningen, försök igen om en stund',
        message: ''
      };
    case ClientErrorCodes.MEMBER_NOT_FOUND:
      return {
        title: 'Det gick inte att hitta medlemmen',
        message: ''
      };
    case ClientErrorCodes.MEMBER_ACTIVE:
      return {
        title: 'Det gick inte att köpa en andel just nu, försök igen om en stund',
        message: ''
      };
    case ClientErrorCodes.GROUP_ACTIVE:
    case ClientErrorCodes.ITS_LIMIT_EXCEEDED:
    case ClientErrorCodes.CONTAINER_NOT_FOUND:
    case ClientErrorCodes.ACTIVATED_DRAW_NOT_FOUND:
      return {
        title: 'Något gick fel, försök igen om en stund',
        message: ''
      };
    case ClientErrorCodes.NO_SHARES_AVAILABLE:
      return {
        title: 'Andelarna är slutsålda',
        message: ''
      };
    case ClientErrorCodes.OPS_INSUFFICIENT_FUNDS:
      return {
        title: 'Ditt saldo är för lågt',
        message: ''
      };
    case ClientErrorCodes.NO_OPS_REPLY:
    case ClientErrorCodes.NO_ITS_REPLY:
      return {
        title: 'Teknikskt fel',
        message: 'Vår server svarar inte på grund av ett tekniskt fel.'
      };
    case ClientErrorCodes.MAX_SHARES_PER_MEMBER_REACHED:
      return {
        title: 'Du har redan köpt max antal andelar',
        message: ''
      };
    default:
      return {
        title: 'Något gick fel',
        errorMessage: 'Något gick fel vid betalningen'
      };
  }
};
const getErrorCodeValue = errorCode => (errorCode === null || errorCode === void 0 ? void 0 : errorCode.split(' ')) || [];
const getClientCode = errorCode => {
  let facillity;
  let code;
  let error;
  if (errorCode) {
    [facillity, code] = getErrorCodeValue(errorCode);
    const facillityCodes = ErrorFacillityCodes[facillity];
    if (facillityCodes) {
      const facillityCodeValues = Object.values(ErrorFacillityCodes[facillity]);
      error = facillityCodeValues.find(value => value.code === code);
    } else {
      logger.info("Unhandled error, errorCode: ".concat(errorCode));
    }
  }
  return error ? error : {
    clientCode: ClientErrorCodes.GENERIC
  };
};
setGlobal('svs.components.payment.common.error.utils', {
  getErrorCodeValue,
  getTitleAndErrorMessageFromClientError,
  getClientCode
});

 })(window);