(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/payment-info-types.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/payment-info-types.js');
"use strict";


const paymentInfoType = {
  PRE: 'pre',
  POST: 'post'
};
setGlobal('svs.components.payment.common.constants.paymentInfoType', paymentInfoType);

 })(window);