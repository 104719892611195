(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/context/interaction-context.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/context/interaction-context.js');
'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
var _severity = new WeakMap();
var _code = new WeakMap();
var _data = new WeakMap();
var _messages = new WeakMap();
var _title = new WeakMap();
class InteractionContext {
  constructor(severity, code, data, title) {
    let messages = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    _classPrivateFieldInitSpec(this, _severity, void 0);
    _classPrivateFieldInitSpec(this, _code, void 0);
    _classPrivateFieldInitSpec(this, _data, void 0);
    _classPrivateFieldInitSpec(this, _messages, void 0);
    _classPrivateFieldInitSpec(this, _title, void 0);
    if (!Object.values(InteractionContext.Severity).includes(severity)) {
      throw new Error("Unknown severity level: ".concat(severity));
    }
    if (!Object.values(InteractionContext.Code).includes(code)) {
      throw new Error("Unknown code: ".concat(code));
    }
    if (data && typeof data !== 'object') {
      throw new Error('data must be of type Object');
    }
    if (typeof title !== 'string') {
      throw new Error("title must be string");
    }
    if (!Array.isArray(messages)) {
      throw new Error('message must be of type Array');
    }
    _classPrivateFieldSet(_severity, this, severity);
    _classPrivateFieldSet(_code, this, code);
    _classPrivateFieldSet(_data, this, Object.assign({}, data));
    _classPrivateFieldSet(_messages, this, messages.slice());
    _classPrivateFieldSet(_title, this, title);
  }
  get severity() {
    return _classPrivateFieldGet(_severity, this);
  }
  get code() {
    return _classPrivateFieldGet(_code, this);
  }
  get data() {
    return _classPrivateFieldGet(_data, this);
  }
  get messages() {
    return _classPrivateFieldGet(_messages, this);
  }
  get title() {
    return _classPrivateFieldGet(_title, this);
  }
}
_defineProperty(InteractionContext, "Code", {
  NOT_ENOUGH_MONEY: 1,
  TIME_LIMIT_EXCEEDED: 2,
  SPELPAUS_ACTIVE: 3
});
_defineProperty(InteractionContext, "Severity", {
  INFO: 'Info',
  ERROR: 'Error',
  CRITICAL: 'Critical'
});
setGlobal('svs.components.payment.common.context.InteractionContext', InteractionContext);

 })(window);