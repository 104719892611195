(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/get-return-url.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/get-return-url.js');

'use strict';

const getReturnUrl = function (location) {
  let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const url = new URL(location);
  const entries = params instanceof URLSearchParams ? params.entries() : Object.entries(params);
  for (const [key, value] of entries) {
    url.searchParams.append(key, value);
  }
  return url;
};
setGlobal('svs.components.payment.common.getReturnUrl', getReturnUrl);

 })(window);