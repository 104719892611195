(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/payable-type.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/payable-type.js');

'use strict';

const PayableType = Object.freeze({
  FRACTION: 'Fraction',
  WAGER: 'Wager'
});
setGlobal('svs.components.payment.common.constants.PayableType', PayableType);

 })(window);