(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/utils/subject.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/utils/subject.js');
'use strict';

const subject = () => {
  let observers = [];

  const unsubscribe = observer => {
    observers = observers.filter(observed => observed !== observer);
  };

  const subscribe = observer => {
    observers.push(observer);
    return () => {
      unsubscribe(observer);
    };
  };

  const publish = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    observers.forEach(observer => {
      observer(...args);
    });
  };
  return {
    subscribe,
    unsubscribe,
    publish
  };
};
setGlobal('svs.components.payment.common.utils.subject', subject);

 })(window);