(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/task-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/task-error.js');

'use strict';

const {
  BaseError
} = svs.components.payment.common.error;

class TaskError extends BaseError {
  constructor(_ref) {
    let {
      clientCode,
      code,
      extraData,
      message
    } = _ref;
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'TaskError';
  }
}
setGlobal('svs.components.payment.common.error.TaskError', TaskError);

 })(window);