(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/utils/pipeline.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/utils/pipeline.js');
'use strict';

const pipeline = function () {
  for (var _len = arguments.length, middlewares = new Array(_len), _key = 0; _key < _len; _key++) {
    middlewares[_key] = arguments[_key];
  }
  const stack = middlewares;

  const push = function () {
    stack.push(...arguments);
  };

  const execute = async context => {
    for (const middleware of stack) {
      await new Promise((resolve, reject) => {
        let called = false;
        try {
          middleware(context)(err => {
            if (called) {
              return;
            }
            called = true;
            if (err) {
              reject(err);
              return;
            }
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      });
    }
  };
  return {
    push,
    execute
  };
};
setGlobal('svs.components.payment.common.utils.pipeline', pipeline);

 })(window);