(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/signup-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/signup-error.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  TaskError
} = svs.components.payment.common.error;
const {
  ClientErrorCodes
} = svs.components.payment.common.error.constants;
class SignupError extends TaskError {
  constructor(_ref) {
    let {
      clientCode,
      code,
      extraData,
      message
    } = _ref;
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'SignupError';
  }
}
_defineProperty(SignupError, "ClientErrorCodes", ClientErrorCodes);
setGlobal('svs.components.payment.common.error.SignupError', SignupError);

 })(window);