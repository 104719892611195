(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/not-implemented-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/not-implemented-error.js');

'use strict';

class NotImplementedError extends Error {
  constructor() {
    super("NotImplementedError: ".concat(new Error().stack.split('\n')[1].slice(3)));
  }
  toJSON() {
    return {
      message: this.message
    };
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
}
setGlobal('svs.components.payment.common.error.NotImplementedError', NotImplementedError);

 })(window);