(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/outcomes-1x2.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/outcomes-1x2.js');
'use strict';

const Outcomes1X2 = Object.freeze({
  ONE: '1',
  X: 'X',
  TWO: '2'
});
setGlobal('svs.components.payment.common.constants.Outcomes1X2', Outcomes1X2);

 })(window);