(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/quick-pick.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/quick-pick.js');
'use strict';

const QuickPick = {
  WEIGHTEDQP: 'WEIGHTEDQP',
  MODIFIEDQP: 'MODIFIEDQP',
  PARTIALQP: 'PARTIALQP'
};
setGlobal('svs.components.payment.common.constants.QuickPick', QuickPick);

 })(window);