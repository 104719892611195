(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/request-payment-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/request-payment-error.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  ClientErrorMessages,
  ClientErrorCodes
} = svs.components.payment.common.error.constants;
const {
  getClientCode
} = svs.components.payment.common.error.utils;
const {
  PaymentError
} = svs.components.payment.common.error;

class RequestPaymentError extends PaymentError {
  constructor(_ref) {
    var _ClientErrorMessages$;
    let {
      clientCode = ClientErrorCodes.GENERIC_ERROR,
      code,
      extraData
    } = _ref;
    const clientError = getClientCode(code);
    if (clientError.clientCode) {
      clientCode = clientError.clientCode;
    }
    const message = (_ClientErrorMessages$ = ClientErrorMessages[clientCode]) !== null && _ClientErrorMessages$ !== void 0 ? _ClientErrorMessages$ : "Generic error, errorCode: ".concat(code);
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'RequestPaymentError';
  }
  get severity() {
    if (this.clientCode === ClientErrorCodes.BAD_BET) {
      return 'warn';
    }
    return super.severity;
  }
}
_defineProperty(RequestPaymentError, "ClientErrorCodes", ClientErrorCodes);
setGlobal('svs.components.payment.common.error.RequestPaymentError', RequestPaymentError);

 })(window);