(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/request-fraction-payment-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/request-fraction-payment-error.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  ClientErrorMessages,
  ClientErrorCodes
} = svs.components.payment.common.error.constants;
const {
  PaymentError
} = svs.components.payment.common.error;
const MMMErrors = Object.freeze({
  2: ClientErrorCodes.INVALID_PARAM,
  12: ClientErrorCodes.MEMBER_NOT_FOUND,
  19: ClientErrorCodes.MEMBER_ACTIVE,
  20: ClientErrorCodes.GROUP_ACTIVE,
  22: ClientErrorCodes.ITS_LIMIT_EXCEEDED,
  23: ClientErrorCodes.NO_SHARES_AVAILABLE,
  24: ClientErrorCodes.OPS_INSUFFICIENT_FUNDS,
  26: ClientErrorCodes.OPS_ACCOUNT_NOT_REACHABLE,
  27: ClientErrorCodes.TECHNICAL_ERROR,
  36: ClientErrorCodes.CONTAINER_NOT_FOUND,
  37: ClientErrorCodes.ACTIVATED_DRAW_NOT_FOUND,
  43: ClientErrorCodes.NO_OPS_REPLY,
  44: ClientErrorCodes.NO_ITS_REPLY,
  47: ClientErrorCodes.MAX_SHARES_PER_MEMBER_REACHED
});

class RequestFractionPaymentError extends PaymentError {
  constructor(_ref) {
    var _ClientErrorMessages$;
    let {
      clientCode = ClientErrorCodes.GENERIC_ERROR,
      code,
      extraData
    } = _ref;
    const mmmErrorCode = MMMErrors[code];
    if (mmmErrorCode) {
      clientCode = mmmErrorCode;
    }
    const message = (_ClientErrorMessages$ = ClientErrorMessages[clientCode]) !== null && _ClientErrorMessages$ !== void 0 ? _ClientErrorMessages$ : "Generic error, errorCode: ".concat(code);
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'RequestFractionPaymentError';
  }
  get severity() {
    if (this.clientCode === ClientErrorCodes.BAD_BET) {
      return 'warn';
    }
    return super.severity;
  }
}
_defineProperty(RequestFractionPaymentError, "ClientErrorCodes", ClientErrorCodes);
setGlobal('svs.components.payment.common.error.RequestFractionPaymentError', RequestFractionPaymentError);

 })(window);