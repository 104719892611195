(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/collection-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/collection-error.js');
'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
var _errors = new WeakMap();
class CollectionError extends Error {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _errors, []);
  }
  addError() {
    _classPrivateFieldGet(_errors, this).push(...arguments);
  }
  get errors() {
    return _classPrivateFieldGet(_errors, this);
  }
  get message() {
    return this.toJSON();
  }
  toJSON() {
    return _classPrivateFieldGet(_errors, this).map(err => {
      var _err$message;
      if (typeof err.toJSON === 'function') {
        return err.toJSON();
      }
      return {
        message: (_err$message = err.message) !== null && _err$message !== void 0 ? _err$message : 'unknown'
      };
    });
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
}
setGlobal('svs.components.payment.common.error.CollectionError', CollectionError);

 })(window);