(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/payment-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/payment-error.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  BaseError
} = svs.components.payment.common.error;
const {
  ClientErrorCodes,
  ClientErrorMessages
} = svs.components.payment.common.error.constants;

class PaymentError extends BaseError {
  constructor(_ref) {
    let {
      clientCode,
      code,
      extraData,
      message: errorMessage
    } = _ref;
    const paymentMessage = ClientErrorCodes[clientCode] ? ClientErrorMessages[clientCode] : 'Payment error';
    const message = errorMessage || paymentMessage;
    super({
      clientCode,
      code,
      extraData,
      message
    });
    this.name = 'PaymentError';
  }
}
_defineProperty(PaymentError, "ErrorCodes", ClientErrorCodes);
setGlobal('svs.components.payment.common.error.PaymentError', PaymentError);

 })(window);