(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/constants/score-reduction.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/constants/score-reduction.js');
'use strict';

const ScoreReduction = Object.freeze({
  HOME: 'HOME',
  AWAY: 'AWAY',
  DRAW: 'DRAW'
});
setGlobal('svs.components.payment.common.constants.ScoreReduction', ScoreReduction);

 })(window);