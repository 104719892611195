(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/error/base-error.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/error/base-error.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
var _code = new WeakMap();
var _clientCode = new WeakMap();
var _extraData = new WeakMap();
class BaseError extends Error {
  constructor(_ref) {
    let {
      clientCode,
      code,
      extraData,
      message
    } = _ref;
    super(message);
    _classPrivateFieldInitSpec(this, _code, void 0);
    _classPrivateFieldInitSpec(this, _clientCode, void 0);
    _classPrivateFieldInitSpec(this, _extraData, void 0);
    _classPrivateFieldSet(_code, this, code);
    _classPrivateFieldSet(_clientCode, this, clientCode);
    _classPrivateFieldSet(_extraData, this, extraData);
    this.name = 'BaseError';
  }
  toJSON() {
    return {
      clientCode: _classPrivateFieldGet(_clientCode, this),
      code: _classPrivateFieldGet(_code, this),
      message: this.message,
      extra: _classPrivateFieldGet(_extraData, this)
    };
  }
  toString() {
    return JSON.stringify(this.toJSON());
  }
  get handledError() {
    return false;
  }
  get severity() {
    return 'info';
  }
  get code() {
    return _classPrivateFieldGet(_code, this);
  }
  get clientCode() {
    return _classPrivateFieldGet(_clientCode, this);
  }
}
setGlobal('svs.components.payment.common.error.BaseError', BaseError);

 })(window);